import React from 'react';
import styles from './Home.module.scss';
import {Button} from "react-bootstrap";

const Home = () => {
    return (
        <div className={styles.container}>
            <a href={`https://start.exactonline.nl/api/oauth2/auth?client_id=${process.env.REACT_APP_EXACT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HOST}/oauth2/callback&response_type=code`} target="_blank">
            <Button variant="primary">
                    Login to Exact
            </Button>
            </a>
        </div>
    );
};

export default Home;