import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/Home";
import ExactResponse from "./pages/ExactResponse";

function App() {
  return (
      <Router>
          <div className="min-vh-100 d-flex flex-column justify-content-between flex-nowrap">
              <header>
              </header>

              <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="/exact-response" element={<ExactResponse/>} />
              </Routes>

              <footer>
              </footer>
          </div>
      </Router>
  );
}

export default App;
