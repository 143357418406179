import React from 'react';
import styles from './ExactResponse.module.scss';
import {useSearchParams} from "react-router-dom";

const ExactResponse = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const result = searchParams.get("result");

    if (!result || result === 'error'){
        return (
            <div className={styles.container}>
                <p>Some problems with authorization. Please, check your credentials and try again.</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <p>Successfully logged in to Exact. Now you can make requests to Exact API.</p>
        </div>
    );
};

export default ExactResponse;